import React, { useEffect, useState } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import { icons } from "../../icons";
import AddIcon from '@material-ui/icons/Add';
import ModalAddCampaignForm from "./ModalAddCampaignForm";
import { getCampaignList } from "../../redux/contactApi";
import Campaign from "./Campaign";
import { Box } from "@material-ui/core";


const CampaignList = (props) => {
  const [expand, setExpand] = useState(false);
  const [show, setShow] = useState(false);
  const [campaigns, setCampaigns] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [count, setCount] = useState(0)




  useEffect(() => {
    if (props.contact_id) {
      loadCampaignList();
    }
  }, [props.contact_id])

  const loadCampaignList = () => {
    getCampaignList({
      contact_id: props.contact_id
    }).then(res => {
      setCount(res.data.length);
      setCampaigns(res.data)
    });
  }

  const reloadCampaignList = () => {
    loadCampaignList();
    setShowModal(false)
    setShow(true)

  }


  const renderCampaigns = () => {

    let dataArray = [];
    if (count > 0) {
      for (let i = 0; i < count; i++) {
        if (campaigns[i] !== undefined && campaigns[i] != null && campaigns[i].campaign !== undefined && campaigns[i].campaign != null) {
          dataArray.push(
            <Campaign reloadCampaignList={reloadCampaignList} contact_id={props.contact_id}
              key={i} data={campaigns[i]} />);
        }
      }
      if (dataArray.length > 0) {
        return dataArray;

      } else {
        return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
          <h6 class="text-gray">no campaign assigned</h6></div>)
      }

    } else {

      return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
        <h6 class="text-gray">no campaign assigned</h6></div>)

    }
  }


  return (
    <div className="" style={{ marginTop: '10px' }}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
        >
          <span className={`${expand ? "g-header-title-for-deal" : "g-header-title"}`}>
            <span className="material-icons">campaign</span> Campaigns{" "}
            <span className="g-header-count" style={{ color: 'black' }}>{count}</span>
          </span>

          <div className="g-header-action-content">
            <span className="g-add-task-button" onClick={() => setShowModal(!showModal)} style={{ background: 'white', height: '26px', width: '26px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}>
              <AddIcon style={{ fontSize: '14px', color: "var(--light_blue)" }} />
            </span>
            <span className={`${expand ? "g-custom-caret-for-deal" : "g-custom-caret"}`}>{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>

          <Box py={2} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {renderCampaigns()}
          </Box>
        </CustomAccordionBody>
      </CustomAccordion>
      {
        showModal && (
          <ModalAddCampaignForm
            contact_id={props.contact_id}
            selectedCampaign={campaigns}
            title={"Add Campaigns"}
            people={props.people}
            isOpen={showModal}
            callBack={() => reloadCampaignList()}
            modalToggle={() => setShowModal(!showModal)}
          />
        )
      }
    </div>
  );
};
export default CampaignList;
