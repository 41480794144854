import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Person } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
/* icons */
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React, { useEffect, useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import OutSideConstants from "../../constants/ConstantFromOutside";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../assets/CustomAccordion";
import icons from "../../assets/icons";
import { getContactDetails, _updateStateField } from "../../redux/contactDetailAction";
import { campaignSingleContactPauseApi, campaignSingleContactRunApi, campaignSingleContactUnsubscribedApi, getAllCampaignByContactApi, getEligibleCampaignsApi, moveCampaignContactApi } from "../../redux/contactDetailApi";



const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer,
    contactId: state.contactDetailReducer.contactId
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params)),
    getContactDetails: params => dispatch(getContactDetails(params)),
})

const App = (props) => {

    const [contactId, setContactId] = useState(props.contactId)
    const [expand, setExpand] = useState(false)
    const [campaigns, setCampaigns] = useState([])
    const [campaignList, setCampaignList] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState('')
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [filterList, setFilterList] = useState([])
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        if (props.contactId != null) {
            setContactId(props.contactId);
            getAllCampaignByContactApi({ contact_id: props.contactId }).then(res => {
                let response = res.data
                if (typeof response.data != 'undefined') {
                    setCampaigns(response.data)
                } else {
                    setCampaigns(response)
                }
            })
        }
    }, [props.contactId])

    useEffect(() => {
        if (expand) {
            setLoading(true)
            setSelectedCampaign('')
            getEligibleCampaignsApi({ contact_id: contactId }).then(res => {
                let response = res.data
                setCampaignList(response.campaigns)
                setLoading(false)
            })
        }
    }, [expand])

    const handleSearch = (event) => {
        setSearch(event.target.value)
        let query = event.target.value
        query = query.toLowerCase()
        let filterList = campaignList.filter(item => {
            let text = item.title.toLowerCase()
            if (text.includes(query)) return true
            return false
        })
        setSelectedCampaign('')
        setFilterList(filterList)
    }

    const renderList = () => {
        let list = campaignList
        if (search != '') {
            list = filterList
        }
        if (list.length == 0) {
            return (
                <span className="noValid">No campagin found</span>
            )
        }
        return list.map((item, index) => {
            return (
                <div className="custom-cursor-pointer" key={index} style={{ display: 'inline-flex', width: '100%' }}>
                    <div className="d-inline-flex" onClick={() => setSelectedCampaign(item.id.toString())} style={{ color: '#525252bd' }}>
                        {item.id == selectedCampaign ? <RadioButtonCheckedIcon className="mr-3" color="secondary" /> : <RadioButtonUncheckedIcon className="mr-3" />}
                        {item.title}
                    </div>
                </div>
            )
        })
    }

    const handleSubmit = () => {
        if (submit) return;

        if (selectedCampaign == '') return;

        setSubmit(true)
        moveCampaignContactApi({
            campaign_id: false,
            contact_id: contactId,
            isSubscribe: 0,
            move_campaign_id: selectedCampaign.toString()
        }).then(response => {
            let res = response.data
            setSubmit(false)
            if (res.status == 'success') {

                // alert(res.html)
                window.showNotification({
                    type: 'SUCCESS',
                    message: res.html
                });
                props.getContactDetails({
                    id: contactId
                })
                setExpand(false)
            }
            else {
                window.showNotification({
                    type: 'ERROR',
                    message: res.html
                });
                // alert('Something went wrong')
            }
        })
    }

    return (
        <div className="left__column columnItem">
            <CustomAccordion expand={expand} className={"collapsible defaultToggle trigger__agency"}>
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`collapsible-header trigger__header border__bottom flex content-between items-center ${expand ? 'expand' : ''}`}>
                    <span className="trigger__subtitle"><Person /> Campaign </span>
                    <span className="ds__trigger_length">{campaigns.filter(item => item.campaign !== null).length}</span>
                    {icons.caretIcon}
                </CustomAccordionHeader>
                <CustomAccordionBody className="collapsible-body">
                    <ul className="templateItems ds__campaign_list_item awesome__scroll_bar">

                        <li>
                            <div className="campaign-each-container">
                                {campaigns.length == 0 &&
                                    <span className="noItemsFound">No campaign is assigned for this contact</span>
                                }

                                {campaigns.length != 0 &&
                                    campaigns.map((item, index) => {
                                        if (item.campaign) {
                                            return (
                                                <CampaignList key={index} data={item} index={index} />
                                            )
                                        }
                                    })
                                }
                            </div>
                        </li>

                        <li className="my-3">
                            <span>You can add {'contact info'} to the following campaigns:</span>
                            <input className="message__area_here common__round_circle campagin-search-height" type="text" onChange={handleSearch} placeholder={'Type and search'} onChange={handleSearch} />
                        </li>

                        <li className="ds__radioItem">
                            {loading &&
                                <div>Loading...</div>
                            }
                            {!loading &&
                                <div>
                                    {renderList()}
                                </div>
                            }
                        </li>

                        {selectedCampaign != '' &&
                            <li className="common__round_circle message__area_here">
                                <span className="d-inline-flex align-items-center" onClick={handleSubmit}>{submit ? <MoreHorizIcon fontSize="small" /> : <AddIcon fontSize="small" />} {submit ? 'Assigning' : 'Assign'} Campaign</span>
                            </li>
                        }
                    </ul>
                </CustomAccordionBody>
            </CustomAccordion>
        </div>
    )
}
const Campaign = connect(mapStateToProps, mapDispatchToProps)(App);
export default Campaign;

const CampaignListApp = props => {
    const { data } = props
    const { contactId } = props.contactDetailReducer

    /* for menu */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const campaignIsPaused = (campaignId) => {
        if (parseInt(OutSideConstants.authId) === parseInt(data.campaign.user_id)) {
            confirmAlert({
                title: 'Campaign is paused',
                message: 'Run the campaign to add contact',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            window.location.replace("/user/campaign/edit/own-" + campaignId);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        } else {
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    const toStart = () => {

        if (parseInt(OutSideConstants.authId) === parseInt(data.campaign.user_id)) {
            confirmAlert({
                title: 'Confirm to run?',
                message: 'Do you want to run this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            campaignSingleContactRunApi({
                                contact_id: contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                props.getContactDetails({
                                    id: contactId
                                })
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        } else {
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }

    }

    const toPause = () => {

        if (parseInt(OutSideConstants.authId) === parseInt(data.campaign.user_id)) {

            confirmAlert({
                title: 'Confirm to pause',
                message: 'Do you want to pause this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            campaignSingleContactPauseApi({
                                contact_id: contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                props.getContactDetails({
                                    id: contactId
                                })
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });

        } else {
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    const toUnsub = () => {

        if (parseInt(OutSideConstants.authId) === parseInt(data.campaign.user_id)) {

            confirmAlert({
                title: 'Confirm to unsubscribe',
                message: 'Do you want to unsubscribe this contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            campaignSingleContactUnsubscribedApi({
                                contact_id: contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                props.getContactDetails({
                                    id: contactId
                                })
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })

        } else {
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    let isRunning = false;
    let unsubscribe = false;
    let isPaused = false;
    let msg = '';

    if ((data.subscription[0] && data.subscription[0].is_unsubscribed == 1)) {
        isRunning = false;
        unsubscribe = true;
        msg = 'Unsubscribed';
    } else {
        if (data.campaign != null) {
            if (data.campaign.status == 3) {
                const pauseContactArr = [2, 4, 7, 8, 9, 10];
                if (data.status == 1) {
                    isRunning = true;
                    msg = 'Running';
                } else if (pauseContactArr.includes(data.status)) {
                    isRunning = false;
                    isPaused = true;
                    msg = 'Paused';
                } else if (data.status == 6) {
                    isRunning = false;
                    isPaused = false;
                    unsubscribe = true;
                    msg = 'Complete';
                }
            } else {
                isRunning = false;
                msg = 'Campaign Paused';
            }
        } else {
            isRunning = false;
            msg = 'Campaign Paused';
        }
    }

    return (
        <div>
            <div className="my-2">
                {data.campaign.title}<br /><code>{data.campaign.user && data.campaign.user.full_name ? data.campaign.user.full_name : ''}</code>
            </div>

            {isRunning && (
                <>
                    <Button aria-controls="simple-menu-2" aria-haspopup="true" onClick={handleClick}>
                        {msg}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem className="dropdownhelper-menuitem-class" onClick={toPause}>Pause</MenuItem>
                        <MenuItem className="dropdownhelper-menuitem-class" onClick={toUnsub}>Unsubscribe</MenuItem>
                    </Menu>
                </>
            )}

            {isPaused && (
                <>
                    <Button aria-controls="simple-menu-2" aria-haspopup="true" onClick={handleClick2}>
                        {msg}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl2}
                        keepMounted
                        open={Boolean(anchorEl2)}
                        onClose={handleClose2}
                    >
                        <MenuItem className="dropdownhelper-menuitem-class" onClick={toStart}>Run</MenuItem>
                        <MenuItem className="dropdownhelper-menuitem-class" onClick={toUnsub}>Unsubscribe</MenuItem>
                    </Menu>
                </>
            )}

            {!isRunning && !unsubscribe && !isPaused && (
                <span onClick={() => campaignIsPaused(data.campaign_id)} className="badge badge-danger campaign-running-btn">
                    <i className="la la-pause"></i><span> {msg}</span>
                </span>
            )}
            {!isRunning && unsubscribe && !isPaused && (<span className="badge badge-danger"><i className="la la-stop"></i><span> {msg}</span></span>)}
        </div>
    )
}
export const CampaignList = connect(mapStateToProps, mapDispatchToProps)(CampaignListApp);