import {useContext, useEffect} from 'react';
import SendEmailHelper from '../assets/Helper';
import CustomMultipleSelect from '../components/CustomMultipleSelect';
import {
    storeBccMailInfo,
    storeCcMailInfo,
    updateCcAndBccFlag,
    updateToEmail,
    updateToEmailSingle
} from '../reducers/Action';
import { EmailSendContext } from "../reducers/GlobalReduers"
import CustomSelect from "../components/CustomSelect";
import {MenuItem} from "@material-ui/core";
import BasicInput from "../../../custom/BasicInput"

const ToEmail = (props) => {
    const {states, dispatch} = useContext(EmailSendContext)

    const hanldeToEmail = (value) => {
        dispatch(updateToEmail(value))

        // single to email select on primary email
        dispatch(updateToEmailSingle(value));

        if (Array.isArray(states.ccMails)){
            if (states.ccMails.includes(value.email)){
                dispatch(storeCcMailInfo({
                    ccMails: [...states.ccMails.filter((email)=>email !== value.email), states.selectedToEmail.email]
                }));
            }else {
                dispatch(storeCcMailInfo({
                    ccMails: [...states.ccMails.filter((email)=>email !== value.email)]
                }));
            }
        }
    }

    // for cc mail option
    const addCcMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if(code == 13) {
            if(e.target.value == ''){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'Enter a mail address to add CC'
                }))
            }
            else if(!SendEmailHelper.validateEmail(e.target.value)){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'Enter a valid mail address to add CC'
                }))
            }
            else if(states.ccMails.includes(e.target.value)){
                dispatch(storeCcMailInfo({
                    isCcValid: false, 
                    showCcValidMessage: 'This mail already added in CC'
                }))
            }
            else{
                let newData = [...states.ccMails];
                newData.push(e.target.value)
                dispatch(storeCcMailInfo({
                    ccMails: newData, 
                    isCcValid: true,
                    textToAddCc: ''
                }))
            }
        }
    }
    const addCcMailChange = (event) => {
        dispatch(storeCcMailInfo({
            textToAddCc: event.target.value,  
            isCcValid: true, 
            showCcValidMessage: ''
        }))
    }
    const removeFromCc = (item, index) => {
        const oldData = [...states.ccMails];
        oldData.splice(index, 1)
        dispatch(storeCcMailInfo({
            ccMails: oldData
        }))
    }
    const handleCcOption = (status) => {
        dispatch(updateCcAndBccFlag({activeCc: status}))
        if(!status){
            dispatch(storeCcMailInfo({
                ccMails: [...states.defaultCc],
                textToAddCc: '',  
                isCcValid: true, 
                showCcValidMessage: ''
            }))
        }
    }
    // cc end
    // bcc start
    const handleBccOption = (status) => {
        dispatch(updateCcAndBccFlag({activeBcc: status}))
        if(!status){
            dispatch(storeCcMailInfo({
                bccMails: []
            }))
        }
    }
    const addBccMail = (e) => {
        var code = (e.keyCode ? e.keyCode : e.which);
        if(code == 13) {
            if(e.target.value == ''){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'Enter a mail address to add BCC'
                }))
            }
            else if(!SendEmailHelper.validateEmail(e.target.value)){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'Enter a valid mail address to add BCC'
                }))
            }
            else if(states.bccMails.includes(e.target.value)){
                dispatch(storeBccMailInfo({
                    isBccValid: false, 
                    showBccValidMessage: 'This mail already added in BCC'
                }))
            }
            else{
                let newData = [...states.bccMails];
                newData.push(e.target.value)
                dispatch(storeBccMailInfo({
                    bccMails: newData, 
                    isBccValid: true,
                    textToAddBcc: '',
                    showBccValidMessage: ''
                }))
            }
        }
    }
    const addBccMailChange = (event) => {
        dispatch(storeBccMailInfo({
            textToAddBcc: event.target.value,  
            isBccValid: true, 
            showBccValidMessage: ''
        }))
    }
    const removeFromBcc = (item, index) => {
        const oldData = [...states.bccMails];
        oldData.splice(index, 1)
        dispatch(storeBccMailInfo({
            bccMails: oldData
        }))
    }

    // bcc end
    return(
        <>
        <div className="g-to-email-content email_send_row to__email__v2">
            <h3 className="g-to-email-text">To</h3>
            {
                states.emailId == null &&
                <CustomSelect
                    formControlClass="g-to-email-select"
                    value={states.selectedToEmail}
                    handleChange={(event) => hanldeToEmail(event.target.value)}
                    placeholder={(<MenuItem className="dropdownhelper-menuitem-class" value="" disabled> Select a mail address </MenuItem>)}
                    item={states.allToEmails}
                    titel_field={'label'}
                    menuItemClass="dropdownhelper-menuitem-class"
                    renderValue={(selected) => {
                        if (selected == '') {
                            return <em>Select a mail address</em>;
                        }
                        return selected.label
                    }}
                />
                // <CustomMultipleSelect
                // className="g-to-email-select"
                // list={states.allToEmails}
                // value={states.selectedToEmails}
                // placeholder={'Select a mail address'}
                // onChange={(event) => {
                //     hanldeToEmail(event.target.value)
                // }}
                // />
            }

            {
                states.emailId != null &&
                <input  className="to__email__v2__to__input" type="text" value={states.emailId} readOnly></input>
            }
           
        {!states.activeCc &&
        <span className="g__email_modal_add_cc_button" onClick={() => handleCcOption(true)}>Add CC</span>
        }
        {!states.activeBcc && 
        <span className="g__email_modal_add_bcc_button" onClick={() => handleBccOption(true)}>Add BCC</span> 
        }
        </div>
        

        {states.activeCc &&
        <div className="g__email_send_cc_body">
            <div className="g__email_send_cc_container">
                <h3 className="g__email_send_cc_title">Cc</h3>
                <div className="g__email_send_cc_content">
                    <div className="g__email_send_cc_mails_content">
                        <div className="g__email_send_cc_mails_content__inner">
                            {states.ccMails.map((item, index) => {
                                return (
                                    <div className="g__email_send_cc_each_email" key={item + "-" + index}>
                                        <span className="g__email_send_cc_each_email_title">{item} </span>
                                        <span className="g__email_send_cc_each_email_remove"
                                              onClick={() => removeFromCc(item, index)}>&times;</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{paddingTop:"28px",paddingLeft:"5px"}}>
                            {/* <input className="g__email_send_cc_add_more" 
                            type="text" onKeyPress={addCcMail} 
                            onChange={addCcMailChange} 
                            value={states.textToAddCc} 
                            placeholder="Add cc mail address"
                            /> */}
                            <BasicInput
                                onKeyPress={addCcMail}
                                value={states.textToAddCc} 
                                onChange={addCcMailChange} 
                                placeholder="Add cc mail address" 
                                fullWidth
                            />
                        </div>
                    </div>
                    <span className="g__email_send_cc_clear_all" onClick={() => handleCcOption(false)}>Clear All</span>
                </div>
            </div>
            {!states.isCcValid &&
                <span className="g__email_send_cc_invalid">{states.showCcValidMessage}</span>
            }
        </div>
        }

        {states.activeBcc &&
        <div className="g__email_send_cc_body">
            <div className="g__email_send_cc_container">
                <h3 className="g__email_send_cc_title">Bcc</h3>
                <div className="g__email_send_cc_content">
                    <div className="g__email_send_cc_mails_content">
                        <div className="g__email_send_cc_mails_content__inner">
                            {states.bccMails.map((item, index) => {
                                return (
                                    <div className="g__email_send_cc_each_email" key={item + "-" + index}>
                                        <span className="g__email_send_cc_each_email_title">{item} </span>
                                        <span className="g__email_send_cc_each_email_remove"
                                              onClick={() => removeFromBcc(item, index)}>&times;</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{paddingTop:"28px"}}>
                            {/* <input className="g__email_send_cc_add_more" 
                            type="text" onKeyPress={addBccMail} 
                            onChange={addBccMailChange} 
                            value={states.textToAddBcc} 
                            placeholder="Add bcc mail address"
                            /> */}
                            <BasicInput
                                onKeyPress={addBccMail}
                                value={states.textToAddBcc} 
                                onChange={addBccMailChange} 
                                placeholder="Add bcc mail address" 
                                fullWidth
                            />
                        </div>
                    </div>
                    <span className="g__email_send_cc_clear_all" 
                    onClick={() => handleBccOption(false)}>Clear All</span>
                </div>
            </div>
            {!states.isBccValid &&
                <span className="g__email_send_cc_invalid">{states.showBccValidMessage}</span>
            }
        </div>
        }

        {/* {states.activeBcc &&
        <div className="g__email_send_bcc_container">
            <h3 className="g__email_send_bcc_title">Bcc</h3>
            <div className="g__email_send_bcc_content">
                <div className="g__email_send_bcc_mails_content">
                    {states.bccMails.map((item, index) => {
                        return(
                            <div className="g__email_send_bcc_each_email" key={item+"-"+index}>
                                <span className="g__email_send_bcc_each_email_title">{item} </span>
                                <span className="g__email_send_bcc_each_email_remove" onClick={() => removeFromBcc(item, index)}>&times;</span>
                            </div>
                        )
                    })}
                    <input className="g__email_send_bcc_add_more" type="text" onKeyPress={addBccMail} onChange={addBccMailChange} value={states.textToAddBcc} placeholder="Add bcc mail address"/>
                </div>
                {!states.isBccValid &&
                <span className="g__email_send_bcc_invalid">{states.showBccValidMessage}</span>
                }
                <span className="g__email_send_bcc_clear_all" onClick={() => handleBccOption(false)}>Clear All</span>
            </div>
        </div>
        } */}

                {/* {toMaildata.addBcc &&
                <div className="d-flex align-items-center ds__row ds__cc_wrapper common__round_circle ds__mt20pxSpace">
                    <h3 className="trigger__modal_title ds__alt ds__email_to_cc_bcc">Bcc</h3>
                    <div className="d-flex align-items-start flex-wrap customColumns">
                    {toMaildata.bccMails.map((item, index) => {
                        return(
                           <div className="ds__singleItem common__round_circle ds__item" key={item+"-"+index}>
                                <span>{item} </span>
                                <span onClick={() => removeFromBcc(item, index)}>&times;</span>
                            </div>
                        )
                    })}

                    <input className="trigger__modal_textField dealValue setFullWidth" type="text" onKeyPress={addBccMail} onChange={addBccMailChange} value={toMaildata.textToAddBcc} placeholder="Add bcc mail address"/>
                    {!toMaildata.isBccValid &&
                    <span className="noValid">{toMaildata.showBccValidMessage}</span>
                    }
                    <span className="clear_all common__round_circle" onClick={() => setToMaildata({...toMaildata, addBcc: false, bccMails: []})}>Clear All</span>
                </div>
                </div>
                } */}
        </>
    )
}
export default ToEmail;