import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Notifications from '../notifications/Notifications';
import Utils, { getAccountData } from '../../helpers/Utils';
import ProfileMenu from './ProfileMenu';
import QuickHelpMenu from './QuickHelpMenu';
import AddNewMenu from './AddNewMenu';
import SearchMenu from './SearchMenu';
import { Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationCount } from '../../actions/notificationAction';
import LoginToParent from './LoginToParent';
import AddContactModal from '../addContact/AddContactModal';
import { Link, useHistory } from "react-router-dom";
import GlobalAddDeal from '../GlobalAddDeal';
import GlobalModal from '../GlobalModal/Modal/GlobalModal';
import GlobalDialerWrapper from '../user/GlobalDialer/GlobalDialerWrapper';
import { StyledMenu } from '../common/StyledMenu';
import { CALL_TYPE, CODE_SNIPPET } from "../../constants/CoreConstants";
import Credit from './Credit';
import { Userpilot } from 'userpilot';
import AddContactModalV2 from '../addContact/addContactModalV2/AddContactModalV2';
Userpilot.initialize('NX-820dde06');

const Header = (props) => {
    let hiddenSection = "";
    const isTempUser = Utils.getAccountData('isTemplateUser');

    if (isTempUser == 1) {
        hiddenSection = hiddenSection + "top-search top-refresh top-dial top-add top-notification top-credit";
    }

    const history = useHistory();

    const dispatch = useDispatch();
    const notificationCount = useSelector(state => state.notificationReducer.notificationCount);
    const [profileEl, setProfileEl] = useState(null);
    const [helpEl, setHelpEl] = useState(null);
    const [notificationEl, setNotificationEl] = useState(null);
    const [newEl, setNewEl] = useState(null);
    const [viewAddContactModal, setViewAddContactModal] = useState(false)
    const [viewAddDealModal, setViewAdDealModal] = useState(false)
    const [sourceList, setSourceList] = useState(null)

    useEffect(() => {
        dispatch(fetchNotificationCount({}));

        Utils.loadSocket(Utils.getAccountData('userId'));

        if (process.env.NODE_ENV !== 'development') {
            Utils.loadCodeSnippets(CODE_SNIPPET.AFTER_LOGIN, {
                agencyId: getAccountData('agencyId'),
                userId: Utils.getAccountData('userId'),
                email: Utils.getAccountData('email'),
                fullName: Utils.getAccountData('fullName'),
            });

            // (function(h,o,t,j,a,r){
            //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            //     h._hjSettings={hjid:2666332,hjsv:6};
            //     a=o.getElementsByTagName('head')[0];
            //     r=o.createElement('script');r.async=1;
            //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            //     a.appendChild(r);
            // })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }


        // if(process.env.NODE_ENV !== 'development' && getAccountData('agencyId') == 1){
        if (getAccountData('agencyId') == 1) {
            Userpilot.identify(Utils.getAccountData('userId'), { name: Utils.getAccountData('fullName'), email: Utils.getAccountData('email') });
        }





    }, []);

    const handleClose = (el) => {
        switch (el) {
            case 'profile':
                setProfileEl(null);
                break;
            case 'help':
                setHelpEl(null);
                break;
            case 'notification':
                setNotificationEl(null);
                break;
            case 'new':
                setNewEl(null);
                break;
            default:
                break;
        }
    };

    const sidebarToggle = e => {
        e.preventDefault();
        if (props.menuCollapsed) {
            window.setCollapsedMenu(false);
        } else {
            window.setCollapsedMenu(true);
        }
    }

    //For mini screen
    const tabSidebarToggle = e => {
        e.preventDefault();
        window.setCollapsedMenu(false);
        document.getElementById('sidenav-left')?.classList.toggle('d-block');
    }

    const addContactCallback = (showModal = null, success = null, contactId = '') => {
        if (showModal !== undefined && showModal != null) {
            setViewAddContactModal(showModal)
        }
        if (success !== undefined && success != null && success) {
            if (contactId !== '') {
                history.push('/contacts/' + contactId)
            }
        }

    }

    window.showAddContactModal = (from = '', sourceList = null) => {
        if (sourceList != null) {
            setSourceList(sourceList)
        }

        handleClose('new')
        setViewAddContactModal(true)
    }

    const showAddDeal = () => {
        handleClose('new');
        setViewAdDealModal(true)
    }

    const showNotificationStart = (message, type = 'SUCCESS') => {
        window.showNotification(type, message);
    }

    return (
        <header className="top__bar">

            {viewAddContactModal && (

                <AddContactModalV2
                    callback={addContactCallback}
                    open={viewAddContactModal}
                    onClose={() => addContactCallback(false, false)}
                    sourceList={sourceList}
                />

                // <AddContactModal
                //     callback={addContactCallback}
                //     open={viewAddContactModal}
                //     onClose={() => addContactCallback(false, false)}
                //     sourceList={sourceList}
                // />

            )}


            {viewAddDealModal &&
                <GlobalModal
                    className="global__add__deal__wrapper"
                    open={viewAddDealModal}
                    title="Add Deal"
                    onClose={() => setViewAdDealModal(false)}
                    buttonText={'Add Deal'}
                    hideFooter={true}
                    // buttonIcon={submitProcessing ? <MoreHorizIcon fontSize="small" /> : IconList.plusIcon26}
                    // handleSubmit={handleSubmit}
                    // showLoader={submitProcessing}
                    // extraButton={newDeal ? '<< Back' : '+ Create New'}
                    // onClickExtraButton={()=>setNewDeal(!newDeal)}
                    // width={props.width}
                >
                    <GlobalAddDeal
                        showNotificationStart={showNotificationStart}
                        callBack={() => { }}
                        onClose={() => setViewAdDealModal(false)}
                    />
                </GlobalModal>
            }
            <div className="navbar-fixed global-top-bar">
                <nav className="navbar bg_dark_blue bg_ds_top_bar">
                    <div className="nav-wrapper container-fluid">
                        <div className="row">
                            <div className="col l4 s4">
                                <div className="top__left_logo">
                                    <div className="sidenav-header">
                                        <div className="sidebar-toggle--btn">
                                            <a href="#!" id="sidebar__toggle" className="d-flex desktop__toggle_icon" onClick={(e) => sidebarToggle(e)}>
                                                <svg width="26" height="19" viewBox="0 0 26 19" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.11839e-07 1.29103C1.41649e-07 0.950033 0.135609 0.623006 0.376995 0.381888C0.618382 0.14077 0.945773 0.00531163 1.28714 0.00531166L11.5843 0.00531256C11.7633 -0.012632 11.944 0.0070931 12.1149 0.063214C12.2858 0.119335 12.443 0.210605 12.5764 0.331141C12.7097 0.451677 12.8164 0.598802 12.8893 0.763026C12.9623 0.92725 13 1.10493 13 1.2846C13 1.46427 12.9623 1.64195 12.8893 1.80617C12.8164 1.97039 12.7097 2.11752 12.5764 2.23806C12.443 2.35859 12.2858 2.44986 12.1149 2.50598C11.944 2.56211 11.7633 2.58183 11.5843 2.56388L1.28714 2.56388C0.947991 2.5639 0.622538 2.43021 0.381517 2.19186C0.140498 1.95352 0.0033923 1.62979 1.11839e-07 1.29103Z"
                                                        fill="#3C7EF3" />
                                                    <path
                                                        d="M2.01919e-06 9.00587C2.049e-06 8.66488 0.136284 8.33785 0.37887 8.09673C0.621456 7.85561 0.950474 7.72015 1.29354 7.72015L24.5773 7.72016C24.7571 7.70221 24.9388 7.72193 25.1105 7.77806C25.2822 7.83418 25.4402 7.92545 25.5743 8.04598C25.7083 8.16652 25.8155 8.31364 25.8888 8.47787C25.9621 8.64209 26 8.81977 26 8.99944C26 9.17911 25.9621 9.35679 25.8888 9.52102C25.8155 9.68524 25.7083 9.83236 25.5743 9.9529C25.4402 10.0734 25.2822 10.1647 25.1105 10.2208C24.9388 10.2769 24.7571 10.2967 24.5773 10.2787L1.29354 10.2787C0.952703 10.2787 0.625631 10.1451 0.383413 9.90671C0.141197 9.66836 0.00341042 9.34463 2.01919e-06 9.00587Z"
                                                        fill="#3C7EF3" />
                                                    <path
                                                        d="M1.42905 15.435C1.2483 15.4171 1.06574 15.4368 0.893158 15.4929C0.720579 15.549 0.561812 15.6403 0.42709 15.7608C0.292368 15.8814 0.184689 16.0285 0.110991 16.1927C0.0372945 16.3569 -0.000781885 16.5346 -0.000781901 16.7143C-0.000781916 16.894 0.0372945 17.0716 0.110991 17.2359C0.184689 17.4001 0.292368 17.5472 0.42709 17.6677C0.561811 17.7883 0.720579 17.8795 0.893158 17.9357C1.06574 17.9918 1.24829 18.0115 1.42905 17.9936L17.0291 17.9936C17.3497 17.9617 17.647 17.8132 17.8633 17.5768C18.0795 17.3404 18.1992 17.033 18.1992 16.7143C18.1992 16.3955 18.0795 16.0881 17.8633 15.8517C17.647 15.6154 17.3497 15.4668 17.0291 15.435L1.42905 15.435Z"
                                                        fill="#3C7EF3" />
                                                </svg>
                                            </a>
                                            <a href="#!" data-target="sidenav-left" onClick={(e) => tabSidebarToggle(e)}
                                               className="sidenav-trigger mobile__menu left">
                                                <svg width="26" height="19" viewBox="0 0 26 19" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.11839e-07 1.29103C1.41649e-07 0.950033 0.135609 0.623006 0.376995 0.381888C0.618382 0.14077 0.945773 0.00531163 1.28714 0.00531166L11.5843 0.00531256C11.7633 -0.012632 11.944 0.0070931 12.1149 0.063214C12.2858 0.119335 12.443 0.210605 12.5764 0.331141C12.7097 0.451677 12.8164 0.598802 12.8893 0.763026C12.9623 0.92725 13 1.10493 13 1.2846C13 1.46427 12.9623 1.64195 12.8893 1.80617C12.8164 1.97039 12.7097 2.11752 12.5764 2.23806C12.443 2.35859 12.2858 2.44986 12.1149 2.50598C11.944 2.56211 11.7633 2.58183 11.5843 2.56388L1.28714 2.56388C0.947991 2.5639 0.622538 2.43021 0.381517 2.19186C0.140498 1.95352 0.0033923 1.62979 1.11839e-07 1.29103Z"
                                                        fill="#3C7EF3" />
                                                    <path
                                                        d="M2.01919e-06 9.00587C2.049e-06 8.66488 0.136284 8.33785 0.37887 8.09673C0.621456 7.85561 0.950474 7.72015 1.29354 7.72015L24.5773 7.72016C24.7571 7.70221 24.9388 7.72193 25.1105 7.77806C25.2822 7.83418 25.4402 7.92545 25.5743 8.04598C25.7083 8.16652 25.8155 8.31364 25.8888 8.47787C25.9621 8.64209 26 8.81977 26 8.99944C26 9.17911 25.9621 9.35679 25.8888 9.52102C25.8155 9.68524 25.7083 9.83236 25.5743 9.9529C25.4402 10.0734 25.2822 10.1647 25.1105 10.2208C24.9388 10.2769 24.7571 10.2967 24.5773 10.2787L1.29354 10.2787C0.952703 10.2787 0.625631 10.1451 0.383413 9.90671C0.141197 9.66836 0.00341042 9.34463 2.01919e-06 9.00587Z"
                                                        fill="#3C7EF3" />
                                                    <path
                                                        d="M1.42905 15.435C1.2483 15.4171 1.06574 15.4368 0.893158 15.4929C0.720579 15.549 0.561812 15.6403 0.42709 15.7608C0.292368 15.8814 0.184689 16.0285 0.110991 16.1927C0.0372945 16.3569 -0.000781885 16.5346 -0.000781901 16.7143C-0.000781916 16.894 0.0372945 17.0716 0.110991 17.2359C0.184689 17.4001 0.292368 17.5472 0.42709 17.6677C0.561811 17.7883 0.720579 17.8795 0.893158 17.9357C1.06574 17.9918 1.24829 18.0115 1.42905 17.9936L17.0291 17.9936C17.3497 17.9617 17.647 17.8132 17.8633 17.5768C18.0795 17.3404 18.1992 17.033 18.1992 16.7143C18.1992 16.3955 18.0795 16.0881 17.8633 15.8517C17.647 15.6154 17.3497 15.4668 17.0291 15.435L1.42905 15.435Z"
                                                        fill="#3C7EF3" />
                                                </svg>
                                            </a>
                                        </div>
                                        <Link to="/" className="logo-container">
                                            <img src={props.menuCollapsed && props.minimizedLogo ? props.minimizedLogo : props.agencyLogo} alt="" loading='lazy' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col l8 s8 auto__dialer__top__wrapper">
                                <ul id="nav-mobile" className=" top__bar_right d-flex align-items-center justify-content-end">
                                    {(hiddenSection.search("top-search") == -1) &&
                                        <li className="top--bar-search-field  liItem dropdown_parent">
                                            <SearchMenu />
                                        </li>
                                    }
                                    {/*{ (hiddenSection.search("top-refresh") == -1) &&*/}
                                    {/*    <LoginToParent/>*/}
                                    {/*}*/}
                                    <LoginToParent />
                                    {/*<li className="add-icon-content  add">*/}
                                    {/*    <Link to='/auto-dialer'*/}
                                    {/*        className="top__bar_add accent--bg--text--color"*/}
                                    {/*    >*/}
                                    {/*        Auto dialer*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    {!Utils.getAccountData('userOnboard') && (hiddenSection.search("top-dial") == -1) &&
                                        <GlobalDialerWrapper />
                                    }

                                    {(hiddenSection.search("top-add") == -1) &&
                                        <li className="add-icon-content  add">
                                            <Button
                                                aria-controls="customized-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                color="primary"
                                                className="top__bar_add accent--bg--text--color"
                                                onClick={e => setNewEl(e.currentTarget)}
                                            >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18 9C18 9.8295 17.928 10.5 17.0985 10.5H10.5V17.0985C10.5 17.9265 9.8295 18 9 18C8.1705 18 7.5 17.9265 7.5 17.0985V10.5H0.9015C0.0734998 10.5 0 9.8295 0 9C0 8.1705 0.0734998 7.5 0.9015 7.5H7.5V0.9015C7.5 0.0719998 8.1705 0 9 0C9.8295 0 10.5 0.0719998 10.5 0.9015V7.5H17.0985C17.928 7.5 18 8.1705 18 9Z" />
                                                </svg>
                                            </Button>
                                            <StyledMenu
                                                id="customized-menu"
                                                anchorEl={newEl}
                                                keepMounted
                                                className="top-add-dropdown-content custom-cursor-pointer"
                                                open={Boolean(newEl)}
                                                onClose={() => handleClose('new')}
                                            >
                                                <AddNewMenu
                                                    handleClose={handleClose}
                                                    showAddContactModal={window.showAddContactModal}
                                                    showAddDeal={()=>{
                                                        window.globalAddDeal(true, null);
                                                        handleClose('new');
                                                    }} />
                                            </StyledMenu>
                                        </li>
                                    }

                                    {(hiddenSection.search("top-notification") == -1) &&
                                        <li className="notification-icon-content  notification">
                                            <Button
                                                aria-controls="customized-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                color="primary"
                                                float="right"
                                                className="top__bar_notification accent--bg--text--color accent--bg--stroke--color"
                                                onClick={e => setNotificationEl(e.currentTarget)}
                                            >
                                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round" fill="none" />
                                                    <path
                                                        d="M11.7295 20C11.5537 20.3031 11.3014 20.5547 10.9978 20.7295C10.6941 20.9044 10.3499 20.9965 9.99953 20.9965C9.64915 20.9965 9.30492 20.9044 9.0013 20.7295C8.69769 20.5547 8.44534 20.3031 8.26953 20"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                </svg>
                                                {
                                                    notificationCount !== 0 &&
                                                    <span className="badge">{notificationCount > 10 ? '10+' : notificationCount}</span>
                                                }

                                            </Button>
                                            <StyledMenu
                                                id="customized-menu"
                                                anchorEl={notificationEl}
                                                className="top-notification-result"
                                                open={Boolean(notificationEl)}
                                                onClose={() => handleClose('notification')}
                                            >
                                                <Notifications onClose={() => handleClose('notification')} />
                                            </StyledMenu>
                                        </li>
                                    }
                                    {/*<li className="help help-icon-content  pr-15">*/}
                                    {/*    <Button*/}
                                    {/*        aria-controls="customized-menu"*/}
                                    {/*        aria-haspopup="true"*/}
                                    {/*        variant="contained"*/}
                                    {/*        color="primary"*/}
                                    {/*        className="top__bar_help"*/}
                                    {/* onClick={e => setHelpEl(e.currentTarget)} */}
                                    {/*    >*/}
                                    {/*        <svg width="11" height="18" viewBox="0 0 11 18" fill="none"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path*/}
                                    {/*                d="M0.000202443 4.824H2.0642C2.0642 2.952 3.2882 1.824 5.2562 1.824C7.2002 1.824 8.4482 3.024 8.4482 4.944C8.4482 7.848 6.1202 8.376 3.3842 8.376H2.6882L2.7842 12.168H4.6802L4.7522 9.888C8.1122 9.888 10.5362 8.448 10.5362 4.944C10.5362 1.896 8.3762 0 5.2322 0C2.0882 0 -0.0237975 1.92 0.000202443 4.824ZM5.1602 15.6C5.1602 14.76 4.5122 14.112 3.7202 14.112C2.8802 14.112 2.2322 14.76 2.2322 15.6C2.2322 16.44 2.8802 17.088 3.7202 17.088C4.5122 17.088 5.1602 16.44 5.1602 15.6Z"*/}
                                    {/*                fill="white"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </Button>*/}
                                    {/*    <StyledMenu*/}
                                    {/*        id="customized-menu"*/}
                                    {/*        anchorEl={helpEl}*/}
                                    {/*        keepMounted*/}
                                    {/*        className="top-help-dropdown-content"*/}
                                    {/*        open={Boolean(helpEl)}*/}
                                    {/*        onClose={() => handleClose('help')}*/}
                                    {/*    >*/}
                                    {/*        <QuickHelpMenu />*/}
                                    {/*    </StyledMenu>*/}
                                    {/*</li>*/}
                                    {
                                        Utils.getAccountData('userIsOwner') &&
                                        <li>
                                            <Credit />
                                        </li>
                                    }
                                    <li className="profile profile-section-content">
                                        <Button
                                            aria-controls="customized-menu"
                                            aria-haspopup="true"
                                            variant="contained"
                                            color="primary"
                                            float="right"
                                            className="user_profile_dropdown top_bar_user_profile"
                                            classes={{ label: 'topbar_small_profile' }}
                                            onClick={e => setProfileEl(e.currentTarget)}
                                        >
                                            <div className="top__bar-user__profile_img">
                                                {
                                                    props.avatar != null ?
                                                        <img className="responsive-img" src={props.avatar} alt="" loading='lazy' /> :
                                                        <Avatar classes={{ colorDefault: 'bg__unset', root: 'profile__avatar-root' }}>{Utils.getInitial(props.userName)}</Avatar>
                                                }
                                            </div>
                                            <div className="top__bar-user__info">
                                                <h5 className="txt_ds_top_bar">{props.userName}</h5>
                                                <p className="txt_ds_top_bar">{props.userEmail}</p>
                                            </div>
                                            <span className="top__bar_arrow_down">
                                                <i className="material-icons right">expand_more</i>
                                            </span>
                                        </Button>
                                        <StyledMenu
                                            id="customized-menu profile-dropdown"
                                            anchorEl={profileEl}
                                            keepMounted
                                            open={Boolean(profileEl)}
                                            className="profile-content"
                                            onClose={() => handleClose('profile')}
                                        >
                                            <ProfileMenu handleClose={handleClose} />
                                        </StyledMenu>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;