import React, { useState, useEffect } from "react";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import SecurityIcon from "@material-ui/icons/Security";
import { icons } from "../../icons";
import EachInsurance from "./EachInsurance.jsx";
import { connect } from "react-redux";
import AddPolicyModal from "./AddPolicyModal.jsx";
import AddNewPolicyTypeModal from "./AddNewPolicyTypeModal.jsx";
import {
  fetchContactPolicies,
  fetchPolicyCompanyAgentData,
} from "../../LeftSide/inboxAction.js";

const Policy = (props) => {
  const [expand, setExpand] = useState(false);
  const [policyId, setPolicyId] = useState(null);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openAddNewPolicyTypeModal, setOpenAddNewPolicyTypeModal] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState("");

  useEffect(() => {
    props?.getPolicyCompanyAgentData();
  }, []);

  useEffect(() => {
    fetchContactPolicyData();
  }, [props.contact?.id]);

  const generateObject = () => {
    const createObjectFromData = (data) => {
      return (data || []).reduce((acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {});
    };

    const policyObject = createObjectFromData(
      props.contactPolicyCompanyAgents.policyTypes
    );
    const companyObject = createObjectFromData(
      props.contactPolicyCompanyAgents.insuranceCompanies
    );
    const agentObject = createObjectFromData(
      props.contactPolicyCompanyAgents.agents
    );

    return {
      policyType: policyObject,
      company: companyObject,
      agent: agentObject,
    };
  };

  const fetchContactPolicyData = () => {
    props.getPolicies({ contact_id: props?.contact?.id });
  };

  const handleCloseModal = (isFetch) => {
    if (isFetch) {
      fetchContactPolicyData();
      setOpenPolicyModal(false);
    } else {
      setOpenPolicyModal(false);
    }
  };
  let count = props.contactPolicies?.length;

  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${
            expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"
          }  ${expand ? "expand" : ""}`}
        >
          <span
            className={`${
              expand ? "g-header-title-for-deal" : "g-header-title"
            }`}
          >
            {" "}
            <SecurityIcon />
            Policy{" "}
            <span className="g-header-count" style={{ color: "black" }}>
              {count}
            </span>
          </span>
          <div className="g-header-action-content">
            <span
              className={`${
                expand ? "g-custom-caret-for-deal" : "g-custom-caret"
              }`}
            >
              {icons.caretIcon}
            </span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <div className="g-contact-appointment-content">
            <div
              className="g-contact-appointment-list awesome__scroll_bar"
              style={{ paddingBottom: "8px", marginTop: "8px" ,paddingRight:'2px',height:'220px'}}
            >
              {props.contactPolicies?.map((item) => (
                <EachInsurance
                  setOpenPolicyModal={setOpenPolicyModal}
                  item={item}
                  dataObject={generateObject()}
                  fetchContactPolicyData={fetchContactPolicyData}
                  setPolicyId={setPolicyId}
                  contact={props.contact}
                />
              ))}
            </div>

            {openPolicyModal && (
              <AddPolicyModal
                open={openPolicyModal}
                contact={props.contact}
                policies={props.contactPolicyCompanyAgents.policyTypes}
                companies={props.contactPolicyCompanyAgents.insuranceCompanies}
                agents={props.contactPolicyCompanyAgents.agents}
                setOpenAddNewPolicyTypeModal={setOpenAddNewPolicyTypeModal}
                onClose={() => setOpenPolicyModal(false)}
                policyId={policyId}
                policyData={props.contactPolicies}
                handleCloseModal={handleCloseModal}
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                sourceList={props?.sourceList}
              />
            )}
            {openAddNewPolicyTypeModal && (
              <AddNewPolicyTypeModal
                open={openAddNewPolicyTypeModal}
                setSelectedPolicy={setSelectedPolicy}
                onClose={() => setOpenAddNewPolicyTypeModal(false)}
              />
            )}
          </div>
        </CustomAccordionBody>
      </CustomAccordion>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
    contactPolicies: state.inboxReducer.contactPolicies,
    contactPolicyCompanyAgents:
      state.inboxReducer.contactPolicyCompanyAgentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPolicies: (params) => dispatch(fetchContactPolicies(params)),
    getPolicyCompanyAgentData: (params) =>
      dispatch(fetchPolicyCompanyAgentData(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Policy);
