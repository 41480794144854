import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import './sharePage.css';
import { ArrowBack, DeviceHub } from "@material-ui/icons";
import { nylasAccessTokenConfig } from "../../api/notificationApi";
import { Button } from "@material-ui/core";

const NylasActivatedPageV2 = () => {
    const history = useHistory();
    const codeObj = useLocation().search;
    const code = new URLSearchParams(codeObj).get('code');
    const state = new URLSearchParams(codeObj).get('state');
    const [dots, setDots] = useState('');
    const [message, setMessage] = useState('Wait while request is being verified');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCloseAble, setIsCloseAble] = useState(false);
    const [backLink, setBackLink] = useState('/');
    const [stateParam, setStateParam] = useState({});

    useEffect(() => {
        if (isProcessing) {
            const intervalId = setInterval(() => {
                setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [isProcessing]);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        setIsProcessing(true);
        const resp = await nylasAccessTokenConfig({ code: code });

        if (resp && resp.success) {
            setTimeout(() => {
                setIsProcessing(false);
                setIsCloseAble(false);
                setMessage("🎉 Congratulations! Request verified successfully");

                setTimeout(() => {
                    setMessage("Please wait while redirecting...");

                    setTimeout(() => {
                        if (state && state === "bulk-email") {
                            history.push("/bulk-email");
                        } else if (state && state !== "bulk-email" && state !== "bulk-email-setting") {
                            history.push("/bulk-email?campaignUid=" + state + "&userConnectedEmailUid=" + resp.data.uid);
                        } else if (state && state === "bulk-email-setting") {
                            history.push("/bulk-email?userConnectedEmailUid=" + resp.data.uid);
                        } else {
                            history.push("/user/profile/email-settings?from=connect-email&success" + "&userConnectedEmailUid=" + resp.data.uid, { tab: 3 });
                        }
                    }, 1000);
                }, 2000);
            }, 3000);
        } else {
            setIsProcessing(false);
            setIsCloseAble(true);
            setMessage(resp?.message || "Sorry! Unable to verify the request. Please try again later!");

            if (state && state === "bulk-email") {
                setBackLink("/bulk-email");
            } else if (state && state !== "bulk-email") {
                setBackLink("/bulk-email?campaignUid=" + state + "&error");
            } else {
                setBackLink("/user/profile/email-settings?from=connect-email");
                setStateParam({ tab: 3 });
            }
        }
    }

    return (
        <div className="share_page__wrapper trigger_share">
            <div className="share_page__inner_wrapper">
                <div className="nylas_connect">
                    <DeviceHub />
                </div>
                <h4>Connect Personal Email</h4>
                {
                    <h5 className="mt-4">{message}
                        {
                            isProcessing ?
                                <span style={{ position: 'absolute' }}>{dots}</span> :
                                ''
                        }
                    </h5>
                }
                {
                    !isCloseAble ?
                        <p>Please do not close the tab or change the page</p> :
                        <p>You can close the tab and try again later</p>
                }

                {
                    isCloseAble &&
                    <Button variant={'contained'} color={'primary'} startIcon={<ArrowBack />} onClick={() => { history.push(backLink, stateParam) }}>
                        back to previous page
                    </Button>
                }
            </div>
        </div>
    );
}

export default NylasActivatedPageV2;