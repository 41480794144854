import { Grid, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import {
  InsuraceCardBody,
  InsuraceCardFooter,
  InsuraceFlexBox,
  InsuraceFlexBoxForHeader,
  InsuraceStatusForActive,
  InsuraceStatusForInActive,
  InsuraceTitle,
  InsuranceCard,
  InsuranceCardWrapper,
  InsuranceHeader,
  InsuranceHeaderFlexBox,
  InsuranceIconContainer,
} from "./PolicyStyle";
import EditPolicyPopOver from "./EditPolicyPopOver";
const useStyle = makeStyles({
  textStyle: {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textWrap: "wrap",
    width: "90%",
    fontWeight: 500,
  },
});

const EachInsurance = ({ item, dataObject, fetchContactPolicyData, setPolicyId,setOpenPolicyModal}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const openEditPolicyPopOver = Boolean(anchorEl);
  const id = openEditPolicyPopOver ? "simple-popover" : undefined;
  const classes=useStyle()
  return (
    <InsuranceCard>
      <InsuranceCardWrapper>
        <InsuranceHeader>
          <InsuraceFlexBoxForHeader>
            <InsuraceTitle>{dataObject?.policyType[item?.policy_id]}</InsuraceTitle>
             <InsuranceHeaderFlexBox>
              {item.status === "ACTIVE" ? (
                <InsuraceStatusForActive>ACTIVE</InsuraceStatusForActive>
              ) : (
                <InsuraceStatusForInActive>INACTIVE</InsuraceStatusForInActive>
              )}
              <InsuranceIconContainer aria-describedby={id} onClick={handleOpenPopOver}>
                <MoreVertIcon />
              </InsuranceIconContainer>
            </InsuranceHeaderFlexBox>
          </InsuraceFlexBoxForHeader>
          <InsuraceFlexBox>
            <Typography style={{ fontWeight: 300, fontSize: "13px" }}>
              Policy: &nbsp;<span style={{fontSize:'12px'}}>{item.unique_key}</span>
            </Typography>
            <Typography style={{ fontSize: "13px",fontWeight: 400 }}>Full Premium&nbsp;:&nbsp; ${item.price}</Typography>
          </InsuraceFlexBox>
        </InsuranceHeader>
        <InsuraceCardBody>
          <Grid container spacing={1}>
            <Grid item lg={6} xl={6} >
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                EFFECTIVE DATE
              </Typography>
              <Typography style={{ fontWeight: 500, fontSize: "11px" }}>{item.effective_date}</Typography>
            </Grid>
            <Grid item lg={6} xl={6}>
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                EXPIRATION DATE
              </Typography>
              <Typography style={{ fontWeight: 500, fontSize: "11px" }}>{item.expiration_date}</Typography>
            </Grid>
            <Grid item lg={12} xl={12}>
              <Typography style={{ color: "#929da9", fontSize: "11px", wordBreak: "break-word", height: "18px" }}>
                INSURANCE COMPANY
              </Typography>
              <Typography className={classes.textStyle} style={{ fontSize: "11px" }}>
                {dataObject?.company[item?.insurance_company_id]}
              </Typography>
            </Grid>
            {/* <Grid item lg={6} xl={1} style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <a href={item.policyLink}>
                <LinkIcon style={{ rotate: "135deg", fontSize: "25px" }} />
              </a>
            </Grid> */}
          </Grid>
        </InsuraceCardBody>
        <InsuraceCardFooter>
          <InsuraceFlexBoxForHeader>
            <Typography style={{ fontWeight: 500, fontSize: "14px" }}>AGENT</Typography>
            <Typography style={{ fontWeight: 500, fontSize: "14px" }}>{dataObject?.agent[item?.agent_id]}</Typography>
          </InsuraceFlexBoxForHeader>
        </InsuraceCardFooter>
      </InsuranceCardWrapper>
   
      {openEditPolicyPopOver && (
        <EditPolicyPopOver
          open={openEditPolicyPopOver}
          setPolicyId={setPolicyId}
          id={id}
          handleClose={handleClosePopOver}
          anchorEl={anchorEl}
          fetchContactPolicyData={fetchContactPolicyData}
          item={item}
          setOpenPolicyModal={setOpenPolicyModal}
        />
      )}
    
    </InsuranceCard>
  );
};

export default EachInsurance;
