import {useContext, useEffect, useState} from 'react';
import LinkFile from "../LinkFile";
import ApiList from '../assets/ApiList';
import { handleLoading, storeProps, storeVnNumber, updateQuickReplyTitle, updateSaveAsTemplateFlag, updateSendingFlag, updateVirtualNumber } from '../reducers/Action';
import SendIcon from '@material-ui/icons/Send';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import QuickReplies from './QuickReplies';
import VnNumber from './VnNumber';
import { VoiceSendContext } from '../reducers/GlobalReduers';
import VoiceFile from './VoiceFile';
import InfoIcon from '@material-ui/icons/Info';
import TimeSetting from '../components/TimeSettings';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SendHelper from '../assets/Helper';
import GlobalContactRelatedContactSelector
    from "../../../GlobalContactRelatedContactSelector/GlobalContactRelatedContactSelector";
import EmailSendSkeleton from '../../Email/pages/EmailSendSkeleton';
import BasicInput from '../../../custom/BasicInput';

const SendModule = (props) => {
    const {states, dispatch} = useContext(VoiceSendContext)

     /* time setting data */
     const [sendTime, setSendTime] = useState('1') //1 = immediate, 2 = custom
     const [date, setDate] = useState(new Date())
     const [time, setTime] = useState(new Date())

     /* for fetching data on scroll */
    const [scrolling, setScrolling] = useState(false);
    const [fetchingMore, setFetchingMore] = useState(false);
    const [needToLoad, setNeedToLoad] = useState(false);
    let currentScrollTopValue = 0;
    /* end */

    useEffect(() => {
        dispatch(storeProps({
            contactId: props.id, 
            from: props.from, 
            onClose: props.onClose,
            sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
            contactInfo: (props.contactInfo !== undefined ? props.contactInfo : null),
            relationSuperContactId: props.relationSuperContactId
        }))
        if(props.id === null){
            // if(props.contactInfo.PhoneNumbers!== undefined && props.contactInfo.PhoneNumbers!== null && props.contactInfo.PhoneNumbers.length > 0){
                // let number = SendHelper.isEmpty(props.contactInfo.PhoneNumbers[0].PhoneNumber) ? "": props.contactInfo.PhoneNumbers[0].PhoneNumber
                let number = ""
                if (props.contactInfo.PhoneNumbers !== undefined && Array.isArray(props.contactInfo.PhoneNumbers) && props.contactInfo.PhoneNumbers.length > 0) {
                    number = SendHelper.isEmpty(props.contactInfo.PhoneNumbers[0].PhoneNumber) ? "No Number available" : props.contactInfo.PhoneNumbers[0].PhoneNumber
                }
                else if (props.contactInfo.CellPhones !== undefined && Array.isArray(props.contactInfo.CellPhones) && props.contactInfo.CellPhones.length >0) {
                    number = SendHelper.isEmpty(props.contactInfo.CellPhones[0].PhoneNumber) ? "No Number available" : props.contactInfo.CellPhones[0].PhoneNumber
                }
                console.log(props.contactInfo);
                console.log(number)
                if(number !== ""){
                    getVirtualNumber()
                    dispatch(handleLoading(false))
                }else{
                    dispatch(handleLoading({
                        isLoading: false, 
                        wentWrong: true,
                        errorText: 'No number found for this contact'
                    }))
                }
            // }
        }
        else{
            getContactLastVn();
        }
        // getVirtualNumber();

        return () => {
            dispatch(storeProps({
                contactId: null, 
                from: '', 
                onClose: null,
                sendSuccessCallBack: null,
            }))
            dispatch(handleLoading(true))
        }
    }, [])

    const getContactLastVn = () => {
        ApiList.getContactLastConnectedVn({contact_id: props.id}).then(res => {
            let response = res.data;
            if(response.status === 'success'){
                dispatch(updateVirtualNumber(response.last_vn_id))
            }
            getVirtualNumber();
        })
        .finally(res => {
            dispatch(handleLoading(false))
        })
        .catch(err => {
            dispatch(handleLoading(false))
        })
    }

    const getVirtualNumber = () => {
        ApiList.getVirtualNumber().then(res => {
            let response = res.data;
            dispatch(storeVnNumber(response.virtual_number))
        }).finally(res => {
            dispatch(handleLoading(false))
        }).catch(res => {
            dispatch(handleLoading({
                isLoading: false, 
                wentWrong: true,
                errorText: 'Something went wrong! Try again later.'
            }))
        })
    }

    const renderMainView = () => {
        if(states.wentWrong){
            return(
                <div className="g-send-voice-error-content">
                    <span>{states.errorText}</span>
                </div>
            )
        }
        if(states.isOpenQuickReply){
            return(
                <QuickReplies scrolling={scrolling} updateScrollOption={updateScrollOption} />
            )
        }
        return(
            <div className="g-voice-send-content awesome__scroll_bar">
                {/* from virtual number */}
                <VnNumber />
                {
                    !states.relationSuperContactId &&
                    <div className="mt-3">
                        <GlobalContactRelatedContactSelector
                            data={{
                                contactId: states.contactId,
                                type: "number",
                                callback: (resp) => {
                                    dispatch(storeProps({
                                        relatedContactIds: resp.contactIds,
                                        contactId: props.id,
                                        from: props.from,
                                        onClose: props.onClose,
                                        sendSuccessCallBack: (props.sendSuccessCallBack !== undefined ? props.sendSuccessCallBack : null),
                                        contactInfo: (props.contactInfo !== undefined ? props.contactInfo : null),
                                        relationSuperContactId: props.relationSuperContactId
                                    }))
                                },
                                contactIds: states.relatedContactIds
                            }}
                        />
                    </div>
                }
                {/* file contetn */}
                <VoiceFile open={props.open} />

                <div className="g-file-max-info">
                    <InfoIcon fontSize="small" />
                    Audio duration must be between 5 seconds to 3 minutes
                </div>

                <div className="g__save__as__content">
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={<Checkbox checked={states.saveAsTemplate} onChange={handleSaveAs} name="save-as-template" />}
                            label="Save as quick reply"
                        />
                    </FormControl>
                </div>
                {states.saveAsTemplate &&
                <div className="g__save__as__template__title">
                    {/* <input placeholder="Enter quick reply title"
                    value={states.templateTitle} 
                    onChange={handleQucikReplyTitleChange} 
                    /> */}
                    <BasicInput
                        value={states.templateTitle} 
                        onChange={handleQucikReplyTitleChange} 
                        placeholder="Enter quick reply title" 
                        fullWidth
                    />
                </div>
                }

                <TimeSetting handleTimesetting={handleTimesetting} />
            </div>
        )
    }

    const handleSend = (e) => {
        if(e !== undefined && e !== null){
            e.preventDefault();
        }

        if (states.selectedTemplateId === null && states.fileData === null) {
            handleShowNotification("No voice file found to send")
            return false;
        }

        if (states.selectedVirtualNumber === '') {
            handleShowNotification("Please select virtual number")
            return false;
        }
        dispatch(updateSendingFlag(true))

        let formData = new FormData();
        formData.append('contact_id', props.id);
        formData.append('origin', 3);
        formData.append('message', 'voice');
        formData.append('messageLevel', 'voice');
        formData.append('virtual_number_id', states.selectedVirtualNumber !== '' ? states.selectedVirtualNumber : null);
        formData.append('voice_template_id', states.selectedTemplateId !== null ? states.selectedTemplateId : '');
        if (states.selectedTemplateId === null && states.fileData !== null) {
            formData.append('voice_file', states.fileData);
        }
        formData.append('schedule_type', sendTime);
        formData.append('date', (sendTime == 2 ? moment(date).format('YYYY-MM-DD') : null));
        formData.append('time', (sendTime == 2 ? moment(time).format('HH:mm') : null));
        formData.append('save_as_template', states.saveAsTemplate);
        formData.append('saveTemplate', states.saveAsTemplate);
        formData.append('saveTemplateTitle', states.templateTitle);
        formData.append('templateFlag', states.templateFlag);

        if (states.relationSuperContactId){
            formData.append('relation_super_contact_id', states.relationSuperContactId);
        }

        if (states.relatedContactIds && states.relatedContactIds[0]){
            states.relatedContactIds.forEach((elem, index) => {
                formData.append(`related_contact_ids[${index}]`, elem);
            });
        }

        if(states.contactId == null){
            formData.append('contact_info', JSON.stringify(states.contactInfo));
            dispatch(updateSendingFlag(true));

            ApiList.sendSmsFromMap(formData, true).then(res => {
                let response = res.data
                if(response.status == "error"){
                    handleShowNotification(response.html)
                }
                else{
                    handleShowNotification(response.html, 'SUCCESS')
                    if(states.sendSuccessCallBack !== undefined && states.sendSuccessCallBack !== null){
                        states.sendSuccessCallBack(response)
                    }
                    if(states.onClose !== undefined && states.onClose !== null){
                        states.onClose()
                    }
                }
            }).finally((res) => {
                dispatch(updateSendingFlag(false))
            })
        }
        else{
            ApiList.submitVoiceSend(formData).then(res => {
                let response = res.data
    
                if(response.status === 'success'){
                    if(sendTime == 2){
                        handleShowNotification("Voice mail schedule set", "SUCCESS")
                    }
                    else{
                        handleShowNotification("Voice mail sent successfully", "SUCCESS")
                    }
                    if(states.onClose != undefined){
                        states.onClose()
                    }
                }
                else{
                    if(response.html === "Contact is blocked"){
                        handleShowNotification(response.html)
                    }
                    else{
                        handleShowNotification("Something went wrong. Try again later.")
                    }
                } 
                dispatch(updateSendingFlag(false))        
            }).catch(error => {
                dispatch(updateSendingFlag(false))
            })
        }
    }

    const handleShowNotification = (message, type="ERROR") => {
        if(window.showNotification !== undefined){
            window.showNotification(type, message);
        }
    }

    const handleTimesetting = (type, value) => {
        if(type == 'date'){
            setDate(value)
        }
        else if(type == 'time'){
            setTime(value)
        }
        else if(type == 'tab'){
            setSendTime(value)
        }
    }
    
    const handleSaveAs = () => {
        dispatch(updateSaveAsTemplateFlag(!states.saveAsTemplate))
        if(!states.saveAsTemplate){
            dispatch(updateQuickReplyTitle(""))
        }
    }

    const handleQucikReplyTitleChange = (event) => {
        dispatch(updateQuickReplyTitle(event.target.value))
    }

    /* for scroll start */
    const handleOnScroll = () => {
        if(fetchingMore)return false;
        
        let container = document.getElementById("modal_voice__body___container___id");
        if(container.scrollTop > currentScrollTopValue){
            let innrerHeight = container.clientHeight;
            let dif = container.scrollHeight - (innrerHeight + container.scrollTop);
            if ( dif < 100 && needToLoad) {
                setScrolling(true)
            }
            else{
                setScrolling(false)
            }
        }
        currentScrollTopValue = container.scrollTop;
    }

    const updateScrollOption = (field, value) => {
        if(field === 'fetchingMore'){
            setFetchingMore(value)
        }else if(field === 'needToLoad'){
            setNeedToLoad(value)
        }
        setScrolling(false)
    }
    /* for scroll end */

    return(
        // eslint-disable-next-line react/jsx-pascal-case
        <LinkFile.modal
            bodyId={'modal_voice__body___container___id'}
            open={props.open}
            title={"Voice Mail Send"}
            onClose={props.onClose}
            buttonText={states.sending ? 'Sending' : 'Send'}
            buttonIcon={states.sending ? <MoreHorizIcon /> : <SendIcon />}
            handleSubmit={handleSend}
			className="ib__voice_send_modal global-medium-modal modify-modal-title"
            handleOnScroll={handleOnScroll}
        >
            <div>
                {states.isLoading ? 
                    <EmailSendSkeleton/>
                    :
                    renderMainView()
                }
            </div>
        </LinkFile.modal>
    )
}
export default SendModule;