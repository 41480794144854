import React, {useContext} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ApiList from '../../ApiList';
import ContactHelper from '../../Helper';
import { ContactDetailContext } from '../../reducer/GlobalReduers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PauseIcon from '@material-ui/icons/Pause';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const CampaignList = props => {
    const {detailStates} = useContext(ContactDetailContext)
    const {data} = props

    /* for menu */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const campaignIsPaused = (campaignId) => {
        if(parseInt(ContactHelper.getAccountData('userId')) === parseInt(data.campaign.user_id)){
            confirmAlert({
                title: 'Campaign is paused',
                message: 'Run the campaign to add contact',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            window.location.replace("/campaign/details/"+campaignId);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        }else{
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    const toStart = () => {
        handleClose()
        handleClose2()
        if(parseInt(ContactHelper.getAccountData('userId')) === parseInt(data.campaign.user_id)){
            confirmAlert({
                title: 'Confirm to run?',
                message: 'Do you want to run this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            ApiList.campaignSingleContactRun({
                                contact_id: detailStates.contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                if(window.showNotification){
                                    window.showNotification("SUCCESS", "Campaign is running. Please wait. I will take a time");
                                }
                                if(detailStates.from === 'deal-details'){
                                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                                        detailStates.dealCallback(true)
                                        // props.reloadDealStage(true)
                                    }
                                }
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        }else{
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }

    }

    const toPause = () => {
        handleClose()
        if(parseInt(ContactHelper.getAccountData('userId')) === parseInt(data.campaign.user_id)){
            confirmAlert({
                title: 'Confirm to pause',
                message: 'Do you want to pause this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            ApiList.campaignSingleContactPause({
                                contact_id: detailStates.contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                if(window.showNotification){
                                    window.showNotification("SUCCESS", "Campaign is pausing. Please wait. I will take a time");
                                }
                                if(detailStates.from === 'deal-details'){
                                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                                        detailStates.dealCallback(true)
                                        // props.reloadDealStage(true)
                                    }
                                }
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }else{
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    const toUnsub = () => {
        handleClose()
        handleClose2()
        if(parseInt(ContactHelper.getAccountData('userId')) === parseInt(data.campaign.user_id)){
            confirmAlert({
                title: 'Confirm to unsubscribe',
                message: 'Do you want to unsubscribe this contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            ApiList.campaignSingleContactUnsubscribed({
                                contact_id: detailStates.contactId,
                                campaign_id: data.campaign.id
                            }).then(res => {
                                if(window.showNotification){
                                    window.showNotification("SUCCESS", "Campaign is unsubscrided.");
                                }
                                if(detailStates.from === 'deal-details'){
                                    if(detailStates.dealCallback !== undefined && detailStates.dealCallback !== null){
                                        detailStates.dealCallback(true)
                                        // props.reloadDealStage(true)
                                    }
                                }
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        }else{
            confirmAlert({
                title: 'Sorry!!!',
                message: 'You are not allow to do this as you are not owner of this campaign.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        }
    }

    let isRunning = false;
    let unsubscribe = false;
    let isPaused = false;
    let msg = '';

    if((data.subscription[0] && data.subscription[0].is_unsubscribed == 1)){
        isRunning = false;
        unsubscribe = true;
        msg = 'Unsubscribed';
    }else{
        if(data.campaign != null){
            if(data.campaign.status == 3){
                const pauseContactArr=[2,4,7,8,9,10];
                if(data.status == 1){
                    isRunning = true;
                    msg = 'Running';
                }else if(pauseContactArr.includes(data.status)){
                    isRunning = false;
                    isPaused = true;
                    msg = 'Paused';
                }else if(data.status == 6){
                    isRunning = false;
                    isPaused = false;
                    unsubscribe= true;
                    msg = 'Complete';
                }
            }else{
                isRunning = false;
                msg = 'Campaign Paused';
            }
        }else{
            isRunning = false;
            msg = 'Campaign Paused';
        }
    }

    return(
        <div className="g-campaign-each-container" key={props.key}>
            <h6 className="g-campaign_name">
                {data.campaign?.title}
            </h6>
            <p className="g-campaign_email pb-2">
                <code>{data.campaign?.user.full_name}</code>
            </p>

            {isRunning && (
                <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {msg}
                    <ArrowDropDownIcon fontSize="small" />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem className="dropdownhelper-menuitem-class" onClick={toPause}>Pause</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" onClick={toUnsub}>Unsubscribe</MenuItem>
                </Menu>
                </>
            )}

            {isPaused && (
                <>
                <Button aria-controls="simple-menu-2" aria-haspopup="true" onClick={handleClick2}>
                    {msg}
                    <ArrowDropDownIcon fontSize="small" />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose2}
                >
                    <MenuItem className="dropdownhelper-menuitem-class" onClick={toStart}>Run</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" onClick={toUnsub}>Unsubscribe</MenuItem>
                </Menu>
                </>
            )}

            {!isRunning && !unsubscribe && !isPaused && (
                <Button onClick={() => campaignIsPaused(data.campaign_id)} className="campaign-paused">
                    <PauseIcon fontSize="medium" />
                    {msg}
                </Button>
            )}
            {!isRunning && unsubscribe && !isPaused && (
                <Button className="campaign-stop">
                    <BlockIcon fontSize="medium" />
                    {msg}
                </Button>
            )}
        </div>
    )
}
export default CampaignList;