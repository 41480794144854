import React, { useState, useEffect } from "react";
import NoteIcon from "@material-ui/icons/Note";
import AddIcon from '@material-ui/icons/Add';
import {
  deleteNoteRightSide,
  getTimeline,
} from "../../../GlobalContactDetailsForDeal/redux/contactApi";
import NoteList from "./NoteList";
import "./right-side-note.css";
import NoteComponent from "../noteNew/NoteComponent";
import { icons } from "../../icons";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion";
import TaskSkeleton from "../../../GlobalContactDetailsForDeal/moreInfo/task/TaskSkeleton";

const ContactNotes = (props) => {
  const [notes, setNotes] = useState([]);
  const [showAddNoteModal, isShowAddNoteModal] = useState(false);
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (show) {
      getContactNotes();
    } else {
      setEditData(null);
      setIsLoading(true);
      setIsEdit(false);
      setEditIndex(null);
    }
  }, [show]);


  const toggleAddNoteModal = (afterEdit = false) => {
    isShowAddNoteModal(!showAddNoteModal);
    if (isEdit && !afterEdit) {
      setIsEdit(false);
      setEditData(false);
      setEditIndex(null);
    }
  };

  const storeEditData = (data, index) => {
    setEditData(data);
    if (data === null) {
      setIsEdit(false);
      setEditIndex(null);
    } else {
      setIsEdit(true);
      setEditIndex(index);
      isShowAddNoteModal(true);
    }
  };

  const deleteItem = (id, index) => {
    deleteNoteRightSide({
      id: id,
    })
      .then((res) => {
        getContactNotes();
        window.refreshConersation();
      })
      .catch((error) => {
        if (window.showNotification !== undefined) {
          window.showNotification("ERROR", "Something went wrong!");
        }
      });
  };

  const getContactNotes = () => {
    getTimeline({
      contact_id: props.contact_id,
      content_type: 16,
      page: 1,
      skip: 0,
    })
      .then((res) => {
        let response = res.data;
        setNotes(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const renderNoteList = () => {
    let showNotes = [];
    notes.map((item, index) => {
      showNotes.push(
        <NoteList
          key={index + "-" + item.message}
          index={index}
          data={item}
          storeEditData={storeEditData}
          deleteItem={deleteItem}
        />
      );
    });
    return showNotes;
  };

  const renderMain = () => {
    if (isLoading) {
      return (
        <div>
          <TaskSkeleton/>
        </div>
      );
    } else {
      if (notes.length === 0) {
        return (
          <div>
            <p style={{display:"flex",justifyContent:"center"}}>No note added!</p>
          </div>
        );
      } else {
        return renderNoteList();
      }
    }
  };

  const updateAddNote = (message = "") => {
    if (isEdit) {
      let allNotes = [...notes];
      allNotes[editIndex].message = message;
      setNotes(allNotes);
      storeEditData(null);
    } else {
      getContactNotes();
    }
    // props.refreshData("collaborator");
  };

  return (
    <div className="" style={{ marginTop: "10px" }}>
      <CustomAccordion expand={show} className="g-contact-details-collapsible">
        <CustomAccordionHeader
          onClick={() => setShow(!show)}
          className={`${show ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${show ? "expand" : ""}`}
        >
          <span className={`${show ? "g-header-title-for-deal" : "g-header-title"}`}>
            <span className="material-icons"><NoteIcon style={{color:show?"#fff":'#181f48'}}/></span> Notes
            {/* <span className="g-header-count">{count}</span> */}
          </span>

          <div className="g-header-action-content">
            <span className="g-add-task-button" onClick={toggleAddNoteModal} style={{background:'white',height:'26px',width:'26px',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%'}}>
            <AddIcon style={{fontSize:'14px',color: "var(--light_blue)"}}/>
            </span>
            <span className={`${show?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
          </div>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={show}>
          <div className="g-contact-deal-content" style={{maxHeight:'200px',overflowY:'auto'}}>{renderMain()}</div>
        </CustomAccordionBody>
      </CustomAccordion>
      {/* <Accordion
            expanded={show} 
        >
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={handleToggleAccordion}
            >
                <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                        <div className="collaborator__list__parant">
                            <div className="collaborator__list__left"> 
                                <span className="collaborator__left__icon">
                                    <NoteIcon />
                                </span> 
                                <span className="collaborator__list--title text-dark-blue">Notes</span>
                            </div>
                            <div className="collaborator__list__right" onClick={(event) => event.stopPropagation()}> 
                                <Avatar onClick={toggleAddNoteModal} style={{ marginLeft: 10 }} ><i className="material-icons rightsidebar__list__addicon">add</i></Avatar>
                            </div>
                        </div>
                    }
                />
            </AccordionSummary>
            <AccordionDetails className="accordion__parent right-side-note">
                {renderMain()}
            </AccordionDetails>
        </Accordion> */}

      {showAddNoteModal && (
        // <AddNote
        //     open={showAddNoteModal}
        //     onClose={toggleAddNoteModal}
        //     data={editData}
        //     isEdit={isEdit}
        //     successCallback={updateAddNote}
        //     contactId={props.contact_id}
        // />
        <NoteComponent
          open={showAddNoteModal}
          onClose={toggleAddNoteModal}
          data={editData}
          isEdit={isEdit}
          successCallback={updateAddNote}
          contactId={props.contact_id}
        />
      )}
    </div>
  );
};
export default ContactNotes;
