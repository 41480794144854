import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from '../components/GlobalContactDetailsForDeal/LeftSide/constants/inboxActionTypes'
import * as INBOX_API from "../components/GlobalContactDetailsForDeal/LeftSide/inboxApi";
import Utils from "../helpers/Utils";

function* inboxWatcher() {
    yield takeEvery(ACTION.FETCH_VIRTUAL_NUMBERS, virtualNumberFlow);
    // yield takeEvery(ACTION.FETCH_CUSTOM_FIELD, customFieldDataFlow)
    yield takeEvery(ACTION.MARK_AS_IMPORTANT, markAsImportantFlow)
    yield takeEvery(ACTION.MARK_AS_UNREAD, markAsUnreadFlow)
    yield takeEvery(ACTION.DELETE_CONTACT, deleteContactFlow)
    yield takeEvery(ACTION.ARCHIVE_CONTACT, archiveContactFlow)
    yield takeEvery(ACTION.GET_CONTACT_POLICIES, fetchContactPolicies)
    yield takeEvery(ACTION.GET_POLICY_COMPANY_AGENT, fetchPolicyCompanyAgent)
    // yield takeEvery(ACTION.MOVE_FROM_ARCHIVE, moveFromArchiveContactFlow)
    // yield takeEvery(ACTION.FETCH_MESSAGE_TEMPLATES, fetchMessageTemplatesFlow);
    // yield takeEvery(ACTION.FETCH_CONTACT_ADDITIONAL_CONTACTS, fetchContactAdditionalContacts)
}

function* virtualNumberFlow(action) {
    try {
        const response = yield call(INBOX_API.fetchVirtualNumber, action.payload);
        let responseData = response.data.data;
        yield put({type: ACTION.STORE_VIRTUAL_NUMBERS, payload: responseData});

    } catch (error) {
        Utils.handleException(error);
    }
}

// function* customFieldDataFlow(action) {
//     try {
//         const response = yield call(INBOX_API.fetchCustomField, action.payload);
//         let responseData = response.data.data;
//         yield put({type: ACTION.STORE_CUSTOM_FIELD, payload: responseData});
//     } catch (error) {
//         Utils.handleException(error);
//     }
// }

function* markAsImportantFlow(action) {
    const {payload} = action;
    try {
        const response = yield call(INBOX_API.markAsImportant, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* markAsUnreadFlow(action) {
    const {payload} = action;
    try {
        const response = yield call(INBOX_API.markAsUnread, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* deleteContactFlow(action) {
    const {payload} = action;
    try {
        const response = yield call(INBOX_API.deleteContact, payload.payload);
        if (payload.callback) payload.callback(response?.data);
        //todo: update leftside data
    } catch (error) {
        Utils.handleException(error);
    }
}

function* archiveContactFlow(action) {
    const {payload} = action;
    try {
        const response = yield call(INBOX_API.archiveContact, payload.payload);
        if (payload.callback) payload.callback(response?.data);
        //todo: update leftside data
    } catch (error) {
        Utils.handleException(error);
    }
}

function* fetchContactPolicies(action){
    const { payload } = action;

    try{
        const response = yield call(INBOX_API.getContactPolicies, payload);
        if (response.success === true) {
            if (response.data) {
                if (Array.isArray(response.data)) {
                    yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: response.data } })
                }
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: [] } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicies: [] } })
    }
}

function* fetchPolicyCompanyAgent(action){
    const { payload } = action;

    try{
        const response = yield call(INBOX_API.getPolicyCompanyAgentData, payload);
        if (response.success === true) {
            if (response?.data) {
                let contactPolicyCompanyAgentData = {
                    policyTypes: [],
                    insuranceCompanies: [],
                    agents: []
                };

                if (response.data?.policyTypes) {
                    contactPolicyCompanyAgentData.policyTypes = response.data.policyTypes.filter((item)=>(item.status === "ACTIVE"));
                }
                if (response.data?.insuranceCompanies) {
                    contactPolicyCompanyAgentData.insuranceCompanies = response.data.insuranceCompanies;
                }
                if (response.data?.agents) {
                    contactPolicyCompanyAgentData.agents = response.data.agents;
                }

                yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: contactPolicyCompanyAgentData } });
            }
        }else {
            yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: {} } })
        }
    }catch (err){
        yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactPolicyCompanyAgentData: {} } })
    }
}


// function* moveFromArchiveContactFlow(action) {
//     const {payload} = action;
//     try {
//         const response = yield call(INBOX_API.moveFromArchive, payload.payload);
//         if (payload.callback) payload.callback(response?.data);
//         //todo: update leftside data
//     } catch (error) {
//         Utils.handleException(error);
//     }
// }

// function* fetchMessageTemplatesFlow(action){
//     try {
//         const response = yield call(INBOX_API.getMessageTemplate, action.payload);
//         let responseData = response.data;
//         if (responseData.status === 'success') {
//             yield put({ type: ACTION.STORE_MESSAGE_TEMPLATE, payload: responseData.html });
//         }
//     } catch (error) {
//         Utils.handleException(error);
//     }
// }

// function* fetchContactAdditionalContacts(action){
//     const { payload } = action;

//     try{
//         const response = yield call(INBOX_API.getAllAdditionalContactApi, payload);

//         if (response.data.success === true) {
//             if (response.data.data) {
//                 if (Array.isArray(response.data.data)) {
//                     yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: response.data.data } })
//                 }
//             }
//         }else {
//             yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
//         }
//     }catch (err){
//         yield put({ type: ACTION.UPDATE_MULTIPLE_DATA, payload: { contactAdditionalContacts: [] } })
//     }
// }

export default function* loginMiddleware() {
    yield all([
        inboxWatcher(),
    ])
}