import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/globalDialerActionTypes'
import * as GLOBAL_DIALER_API from "../api/globalDialerApi";
import Utils from "../helpers/Utils";

function* globalDialerWatcher() {
    yield takeEvery(ACTION.FETCH_CURRENT_CREDIT, getCurrentCreditFlow)
    yield takeEvery(ACTION.GET_CONTACT, getContactFlow)
    yield takeEvery(ACTION.SEARCH_CONTACT, getContactFlow)
    yield takeEvery(ACTION.FETCH_VIRTUAL_NUMBER, getVirtualNumberFlow)
}

function* getCurrentCreditFlow(action) {
    try {
        const response = yield call(GLOBAL_DIALER_API.getCurrentCredit, action.payload);
        yield put({ type: ACTION.STORE_CURRENT_CREDIT, payload: { currentCredit: response.data.currentCredit } });
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getContactFlow(action) {
    try {
        yield put({ type: ACTION.PUT_CONTACT, payload: { recentContactLoading: true } });
        const response = yield call(GLOBAL_DIALER_API.getContactWithPagination, {
            searchKey: action.payload.searchKey, searchColumn: action.payload.searchColumn,
            perPage: 10
        });
        let activeContact = {};
        if (response.data.selectedContact != null && typeof response.data.selectedContact !== 'undefined') {
            activeContact = {
                contact_id: response.data.selectedContact.id,
                user_id: response.data.selectedContact.user_id,
                first_name: response.data.selectedContact.first_name,
                last_name: response.data.selectedContact.last_name,
                number: response.data.selectedContact.number,
                current_user: response.data.selectedContact.current_user,
            }
        }
        yield put({
            type: ACTION.PUT_CONTACT, payload: {
                recentContacts: response.data.contacts.data,
                contactSearchPageNo: response.data.contacts.current_page,
                contactSearchLastPage: response.data.contacts.last_page,
                activeContact: activeContact,
                recentContactLoading: false
            }
        });
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getVirtualNumberFlow(action) {
    try {
        const response = yield call(GLOBAL_DIALER_API.getVirtualNumber, action.payload);
        let virtualNumbers = response.data.data;
        let fromNumber = virtualNumbers.filter(number => number.is_default === 1)

        yield put({ type: ACTION.STORE_VIRTUAL_NUMBER, payload: { virtualNumbers: virtualNumbers, fromNumber: fromNumber[0] ? fromNumber[0].virtual_number : virtualNumbers[0] ? virtualNumbers[0].virtual_number : '' } })
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* globalDialerMiddleware() {
    yield all([
        globalDialerWatcher()
    ])
}