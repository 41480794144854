import HttpRequest from "./HttpRequest";
import Api from "../constants/Api";

export const getInsuranceCompanies = (payload) => {
  return HttpRequest.get(Api.insuranceCompanies, payload);
};

export const lostDealFormData = (payload) => {
  return HttpRequest.get(Api.lostDealFormData, payload);
};

export const lostDealRecycle = (payload) => {
  return HttpRequest.post(Api.lostDealRecycle, payload);
};